// Body
$body-bg: #fff;

// the variables below are some pre-defined colors. Each one has a unique class so you can easily use it on any element. Open the colors page (shortcode-colors.php) to see more.

// Gray tones
$color-gray-dark:                 #2b2b2c;
$color-gray:                      #55595c;
$color-gray-light:                #818a91;
$color-gray-lighter:              #eceeef;
$color-gray-lightest:             #f7f7f9;

// Specialized colors
$color-minimalist: rgba(235, 235, 235, 1);

// White
$color-white: rgb(255,255,255);
$color-white-text: #333;

// Black
$color-black: #000000;
$color-black-text: #FFF;

// Dark
$color-dark: #2b2b2c;
$color-dark-text: #fff;

// Space gray
$color-space-gray: #282d33;
$color-space-gray-text: #FFF;

// Blue
$color-blue: rgb(0,122,255);
$color-blue-text: #FFF;

// teal blue
$color-teal-blue: rgb(90,200,250);
$color-teal-blue-text: #FFF;

// Green
$color-green: rgb(76,217,100); //** text color for backgrounds using $colorGreen
$color-green-text: #FFF;

// Red
$color-red: rgb(255,59,48); //** text color for backgrounds using $colorRed
$color-red-text: #FFF;

// Pink
$color-pink: rgb(255,45,85); //** text color for backgrounds using $colorPink
$color-pink-text: #FFF;

// Orange
$color-orange: rgb(255,149,0); //** text color for backgrounds using $colorOrange
$color-orange-text: #FFF;

// Yellow
$color-yellow: rgb(255,204,0); //** text color for backgrounds using $colorYellow
$color-yellow-text: #FFF;

// Purple
$color-purple: rgb(88,86,214); //** text color for backgrounds using $colorPurple
$color-purple-text: #FFF;

// Base color 1 (Site color 1)
$color-base-1: $color-gray-light; //#3452ff; //#e61728; //#1C6BA0 #4579ef
$color-base-text-1: #FFF;

// Base color 2 (Site color 2)
$color-base-2: #292f36;
$color-base-text-2: #FFF;

// Base color 3. Choose a neutral color such gray or light gray
$color-base-3: $color-gray-light;
$color-base-text-3: rgba(255, 255, 255, 0.9);

// Base color 4. Choose a dark color (or a light one for the dark theme)
$color-base-4: #2B2B2B;
$color-base-text-4: #FFF;

// Base color 5. Choose a light color (or a dark one for the dark theme)
$color-base-5: #FFF;
$color-base-text-5: #0A0814;

// Bootstrap's main colors
$color-primary: $color-gray;
$color-success: $color-green;
$color-danger: $color-red;
$color-warning: $color-orange;

// Background colors used for sections (.slice)
$section-color-1: #FFF;
$section-color-2: #fcfcfc;
$section-color-3: $color-space-gray;
$section-color-4: darken($color-space-gray, 10%);

// Social media colors
$color-facebook: #3b5998;
$color-facebook-text: #fff;

$color-twitter: #1da1f2;
$color-twitter-text: #fff;

$color-instagram: #e1306c;
$color-instagram-text: #fff;

$color-linkedin: #0077b5;
$color-linkedin-text: #fff;

$color-pinterest: #bd081c;
$color-pinterest-text: #fff;

$color-dribbble: #444444;
$color-dribbble-text: #fff;

$color-google: #dd4b39;
$color-google-text: #fff;

$color-googleplus: #dd4b39;
$color-googleplus-text: #fff;

$color-skype: #00aff0;
$color-skype-text: #fff;

// Main body color (used for large texts, paragraphs)
$body-color: $color-gray-dark;

// Fonts
$font-family-base: 'Raleway', sans-serif;
$font-family-alt: 'Nunito', sans-serif;

$font-size-root: 20px;

$font-size-base: 1rem;
$font-size-lg:   1.25rem;
$font-size-sm:   .875rem;
$font-size-xs:   .75rem;

$line-height-base: 1.5;

// Paragraph font setttings
$paragraph-font-size: 1rem; //0.875rem;
$paragraph-line-height: 1.4rem;

// Headings
$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.25rem;
$font-size-h5: 1.125rem;
$font-size-h6: 1rem;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;

$heading-text-transform: none;
$heading-font-weight: 600;
$heading-color: rgb(17, 17, 17);
$heading-inverse-color: $color-white;
$heading-light-color: rgba(0, 0, 0, 0.6);

// Links
$link-color:            $color-base-1;
$link-decoration:       none;
$link-color-hover:      darken($link-color, 15%);
$link-decoration-hover: none;

// Section titles
$section-title-text-transform: none;
$section-title-font-size: $font-size-h2;
$section-title-font-weight: 500;
$section-title-color: $color-gray-dark;
$section-title-color-inverse: $color-white;

$section-title-delimiter-width: 23px;

$section-title-delimiter-border-width: 2px;
$section-title-delimiter-border-color: $color-gray-dark;
$section-title-delimiter-border-color-inverse: $color-white;

// Spacing
$spacer:   1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;

// Borders
$border-color-base: rgb(241, 241, 241);
$border-color-inner: rgba(243, 243, 243, 0.7);

// Border radius
$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;
$border-radius-xs: 0.1rem;

// Navbar (default)
$navbar-bg: rgb(255, 255, 255);
$navbar-border-color: rgb(241, 241, 241);

// Navbar (light)
$navbar-light-bg: rgb(242, 242, 242);
$navbar-light-border-color: rgb(213, 213, 213);

// Navbar (dark)
$navbar-dark-bg: rgb(0, 0, 0);
$navbar-dark-border-color: rgb(0, 0, 0);

// Navbar links
$navbar-nav-link-font-family: $font-family-alt;
$navbar-nav-link-font-size: 0.875rem;
$navbar-nav-link-font-weight: 500;
$navbar-nav-link-text-transform: none;
$navbar-nav-link-letter-spacing: 0;
$navbar-nav-link-border-radius: 0;

// Navbar (default) links and text
$navbar-nav-link-bg: transparent;
$navbar-nav-link-bg-hover: $color-base-1;
$navbar-nav-link-bg-active: $color-base-1;

$navbar-nav-link-color: #555;
$navbar-nav-link-color-hover: $color-base-text-1;
$navbar-nav-link-color-active: $color-base-text-1;

$navbar-nav-text-color: rgba(0, 0, 0, 0.3);

// Navbar (light) - link colors
$navbar-light-nav-link-bg: transparent;
$navbar-light-nav-link-bg-hover: transparent;
$navbar-light-nav-link-bg-active: transparent;

$navbar-light-nav-link-color: rgba(0, 0, 0, 0.5);
$navbar-light-nav-link-color-hover: rgba(0, 0, 0, 0.9);
$navbar-light-nav-link-color-active: $color-base-1;

$navbar-light-nav-text-color: rgba(18, 18, 18, 1);

// Navbar (dark) - link colors
$navbar-dark-nav-link-bg: transparent;
$navbar-dark-nav-link-bg-hover: transparent;

$navbar-dark-nav-link-color: rgba(255, 255, 255, 0.7);
$navbar-dark-nav-link-color-hover: rgba(255, 255, 255, 0.8);
$navbar-dark-nav-link-color-active: rgba(255, 255, 255, 0.9);

$navbar-dark-nav-text-color: rgba(255, 255, 255, 0.3);

// Navbar icon links
$navbar-nav-icon-link-border-radius: 100%;

// Navbar (light) icon links
$navbar-nav-icon-link-bg: transparent;
$navbar-nav-icon-link-color: #333;
$navbar-nav-icon-link-border-color: #f3f3f3;

// Navbar (light) icon links: Hover and Focus state
$navbar-nav-icon-link-bg-hover: transparent;
$navbar-nav-icon-link-color-hover: $color-base-1;
$navbar-nav-icon-link-border-color-hover: $color-base-1;

// Navbar (dark) icon links
$navbar-dark-nav-icon-link-bg: transparent;
$navbar-dark-nav-icon-link-color: rgba(255, 255, 255, 0.5);
$navbar-dark-nav-icon-link-border-color: rgba(255, 255, 255, 0.7);

// Navbar (dark) icon links: Hover and Focus state
$navbar-dark-nav-icon-link-bg-hover: transparent;
$navbar-dark-nav-icon-link-color-hover: $color-base-1;
$navbar-dark-nav-icon-link-border-color-hover: $color-base-1;

// Navbar dropdown menu
$navbar-dropdown-menu-border-radius: 3px;
$navbar-dropdown-menu-margin-top: 0;

// Navbar dropdown menu (light)
$navbar-dropdown-menu-bg: #FFF;
$navbar-dropdown-menu-color: #666;
$navbar-dropdown-menu-border-top: 0;

// Navbar dropdown menu (dark)
$navbar-dropdown-dark-menu-bg: #292f36;
$navbar-dropdown-dark-menu-color: rgba(255, 255, 255, 0.5);
$navbar-dropdown-dark-menu-border: 0;
$navbar-dropdown-dark-menu-border-top: 0;

// Navbar dropdown menu items
$navbar-dropdown-item-font-size: 0.8rem;
$navbar-dropdown-item-font-weight: 400;
$navbar-dropdown-item-text-transform: capitalize;

// Navbar (light) dropdown menu items
$navbar-dropdown-item-bg: rgb(255, 255, 255);
$navbar-dropdown-item-color: #666;
$navbar-dropdown-item-border-color: #f9f9f9;

// Navbar (light) dropdown menu items: Hover and focus state
$navbar-dropdown-item-bg-hover: transparent;
$navbar-dropdown-item-color-hover: $color-base-1;

// Navbar (light) dropdown menu components
$navbar-dropdown-title-color: rgba(0, 0, 0, 0.7);

// Navbar (dark) dropdown menu items
$navbar-dropdown-dark-item-bg: rgba(19, 19, 19, 1);
$navbar-dropdown-dark-item-color: rgba(255, 255, 255, 0.7);
$navbar-dropdown-dark-item-border-color: rgba(30, 30, 30, 0.7);

// Navbar (dark) dropdown menu items: Hover and focus state
$navbar-dropdown-dark-item-color-hover: $color-base-1;
$navbar-dropdown-dark-item-bg-hover: transparent;

// Navbar (dark) dropdown menu components
$navbar-dark-dropdown-title-color: rgba(255, 255, 255, 0.7);

// Navbar (light) toggle button (for collapsable menu)
$navbar-toggle-bg: transparent;
$navbar-toggle-border-color: transparent;
$navbar-toggle-border-radius: 0;
$navbar-toggle-color: rgba(0, 0, 0, 0.5);

// Navbar (light) toggle button (for collapsable menu): Hover and Focus state
$navbar-toggle-bg-hover: transparent;
$navbar-toggle-border-color-hover: transparent;
$navbar-toggle-color-hover: rgba(0, 0, 0, 0.8);
$navbar-toggle-color-active: $color-base-1;

// Navbar (dark) toggle button (for collapsable menu)
$navbar-dark-toggle-bg: transparent;
$navbar-dark-toggle-border-color: transparent;
$navbar-dark-toggle-border-radius: 0;
$navbar-dark-toggle-color: rgba(255, 255, 255, 0.5);

// Navbar (dark) toggle button (for collapsable menu): Hover and Focus state
$navbar-dark-toggle-bg-hover: transparent;
$navbar-dark-toggle-border-color-hover: transparent;
$navbar-dark-toggle-color-hover: rgba(255, 255, 255, 0.7);
$navbar-dark-toggle-color-active: rgba(255, 255, 255, 0.9);

// Navbar settings for collapsible menu
$navbar-collapse-nav-link-border-color: rgba(0, 0, 0, 0.1);

$navbar-collapse-dropdown-item-border-color: rgba(0, 0, 0, 0.1);

// Top navbar (light)
$top-navbar-item-delimiter-color: rgb(241, 241, 241);

// Sidebar
$sidebar-bg: #fafafa;
$sidebar-2-bg: #f8fafb;
$sidebar-border-color: $border-color-base;
$sidebar-object-border-color: darken($sidebar-bg, 10%);
$sidebar-heading-color: rgba(0, 0, 0, 0.7);
$sidebar-text-color: rgba(0, 0, 0, 0.5);

$sidebar-inverse-bg: rgb(242, 242, 242);
$sidebar-inverse-border-color: rgb(242, 242, 242);
$sidebar-inverse-object-border-color: rgb(242, 242, 242);

$sidebar-inverse-heading-color: rgba(255, 255, 255, 1);
$sidebar-inverse-heading-light-color: rgba(255, 255, 255, 0.8);
$sidebar-inverse-text-color: rgba(255, 255, 255, 0.5);
$sidebar-inverse-link-color: rgba(255, 255, 255, 0.8);
$sidebar-inverse-link-color-hover: rgba(255, 255, 255, 1);

// Slidebar
$slidebar-bg: #1f1f1f;
$slidebar-border-color: transparent;
$slidebar-color: rgba(255, 255, 255, 0.5);
$slidebar-color-hover: rgba(255, 255, 255, 0.7);
$slidebar-heading-color: $color-gray-light;

// Breadcrumb
$page-title-bg: #fcfcfc;
$page-title-color: #333;
$page-title-font-size: $font-size-h4;
$page-title-font-weight: 600;
$page-title-link-color: #787878;
$page-title-border-color: rgb(241, 241, 241);
$page-title-letter-spacing: 0;

$page-title-breadcrumb-item-color: $color-gray-lighter;

// Cards
$card-spacer-x:            1.5rem;
$card-spacer-y:            1.5rem;
$card-border-width:        1px;
$card-border-radius:       $border-radius;

$card-wrapper-bg: #fff;

$card-bg:                  #fff;
$card-border-color:        rgba(241, 241, 241, 1);
$card-header-bg:           #f1f1f1;
$card-footer-bg:           #f1f1f1;
$card-title-color:         #f3f3f3;
$card-heading-color: rgba(0, 0, 0, 1);
$card-heading-light-color: rgba(0, 0, 0, 0.8);
$card-text-color: rgba(0, 0, 0, 0.5);
$card-link-color: rgba(0, 0, 0, 0.8);
$card-link-color-hover: rgba(0, 0, 0, 1);

$card-inverse-bg:                  #1b1e23;
$card-inverse-border-color:        rgba(0, 0, 0, .15);
$card-inverse-header-bg:           #333;
$card-inverse-footer-bg:           #333;
$card-inverse-title-color:         #f3f3f3;
$card-inverse-heading-color: rgba(255, 255, 255, 1);
$card-inverse-heading-light-color: rgba(255, 255, 255, 0.8);
$card-inverse-text-color: rgba(255, 255, 255, 0.5);
$card-inverse-link-color: rgba(255, 255, 255, 0.8);
$card-inverse-link-color-hover: rgba(255, 255, 255, 1);

// Hover effects
$hover-effect-mask-bg: rgba(0, 0, 0, .8);

// Product actions
$product-sportswear-bg: #FFF;
$product-actions-bg: rgba(255, 255, 255, .9);

// Solid shadow
$solid-shadow-bg: #f9f9f9;
$solid-shadow-border-color: $border-color-base;

// Buttons
$btn-font-size: 0.875rem;
$btn-font-weight: 600;
$btn-font-family: $font-family-alt;
$btn-letter-spacing: 0;
$btn-text-transform: capitalize;
$btn-outline-border-width: 1px;
$btn-border-radius: 2px;

// Tables
$table-odd-row-bg: #f6f9fb;
$table-odd-row-bg-hover: darken($table-odd-row-bg, 5%);
$table-th-color: $color-gray-dark;
$table-td-color: $color-gray-light;

// Forms
$form-control-bg: #FFF;
$form-control-bg-focus: #f9f9f9;
$form-control-disabled-bg: #e9ecef;
$form-control-color: #555;
$form-control-color-focus: #333;
$form-control-placeholder-color: rgba(0, 0, 0, 0.4);
$form-control-placeholder-color-focus: rgba(0, 0, 0, 0.8);
$form-control-border-width: 1px;
$form-control-border-color: #e6e6e6;
$form-control-border-color-focus: #e6e6e6;

$form-control-border-radius: 2px;
$form-control-font-size: 0.875rem;
$form-control-font-weight: 400;

$form-label-font-weight: 400;
$form-label-font-size: 0.8rem;
$form-label-text-transform: none;
$form-label-color: rgba(0, 0, 0, 0.7);

// NO UI Slider
$noui-slider-bg: #FAFAFA;
$noui-slider-connect-bg: $color-base-1;
$noui-slider-connect-disabled-bg: #b2b2b2;

$noui-handle-bg: #fff;
$noui-handle-border-color: #d9d9d9;
$noui-handle-border-radius: 100%;

$noui-origin-border-radius: 2px;
$noui-target-border-radius: 4px;
$noui-target-border-color: darken($border-color-base, 3%);


// Sort list filters
$sort-list-filter-color: $color-gray-dark;
$sort-list-filter-color-hover: $color-gray-light;
$sort-list-filter-color-active: $color-base-1;
$sort-list-filter-font-size: $font-size-xs;
$sort-list-filter-font-family: $font-family-alt;
$sort-list-filter-font-weight: 600;

// Sort list items
$sort-list-item-bg: rgba(0, 0, 0, 0.85);
$sort-list-item-color: $color-gray-dark;
$sort-list-item-color-hover: $color-gray-light;
$sort-list-item-color-active: $color-base-1;
$sort-list-item-font-size: $font-size-sm;
$sort-list-item-font-family: $font-family-alt;
$sort-list-item-font-weight: 600;
$sort-list-item-title-transform: uppercase;


// Sort list filter bar
$sort-list-bar-bg: #131313;
$sort-list-bar-title-color: rgba(255, 255, 255, 0.8);
$sort-list-bar-color: rgba(255, 255, 255, 0.8);
$sort-list-bar-color-hover: rgba(255, 255, 255, 1);
$sort-list-bar-color-active: $color-base-1;
$sort-list-bar-font-size: $font-size-sm;
$sort-list-bar-font-weight: 500;
$sort-list-bar-text-transform: none;
$sort-list-bar-button-color: #999;
$sort-list-bar-button-color-hover: #333;


// List groups

$list-group-bg:                     #FFF;
$list-group-hover-bg:               $color-gray-lighter;
$list-group-border-color:           rgba(#000, .125);

// Carousel
$carousel-control-bg: rgba(0, 0, 0, 0.3);
$carousel-control-bg-hover: $color-base-1;
$carousel-control-color: #fff;
$carousel-control-color-hover: $color-base-text-1;
$carousel-caption-bg: rgba(0, 0, 0, 0.5);
$carousel-caption-color: #fff;

// Component (values applied to all Bootstrap components as default)
$component-bg: #f3f3f3;
$component-color: #333;
$component-border-color: $border-color-base;

// Collapse
$collapse-border-color: $border-color-base;

// Nav pills
$nav-pill-bg: #eee;
$nav-pill-bg-hover: $color-base-1;
$nav-pill-bg-active: $color-base-1;

$nav-pill-color: $color-gray-dark;
$nav-pill-color-hover: $color-gray-dark;
$nav-pill-color-active: $color-gray-dark;

$nav-pill-border-color: transparent;
$nav-pill-delimiter-color: #ddd;

// Pagination
$pagination-border-radius: $border-radius-sm;
$pagination-font-size: 0.7rem;

$pagination-bg: transparent;
$pagination-color: $color-gray-light;
$pagination-border-color: $color-gray-lighter;

$pagination-bg-hover: $color-gray-lighter;
$pagination-color-hover: $color-gray-dark;
$pagination-border-color-hover: $color-gray-lighter;

$pagination-bg-active: $color-base-1;
$pagination-color-active: $color-base-text-1;
$pagination-border-color-active: $color-base-1;

$pagination-bg-disabled: $color-gray-lighter;
$pagination-color-disabled: $color-gray-light;
$pagination-border-color-disabled: $color-gray-lighter;

// Progress
$progress-bg: #f1f1f1;
$progress-bg-active: $color-base-1;

$progress-inverse-bg: rgba(0, 0, 0, 0.3);

// Rating stars
$star-rating-color: $color-gray-light;
$star-rating-color-active: $color-yellow;

$favorite-color: $color-yellow;
$like-color: $color-blue;
$love-color: $color-red;

// tabs
$tab-panel-border-color: rgba(0,0,0,.125);
$tab-content-bg: #FFF;
$tab-content-border-color: rgba(0,0,0,.125);

$nav-tabs-border-color: rgba(0,0,0,.125);
$nav-tab-color: $color-gray-dark;
$nav-tab-border-color: rgba(0,0,0,.125);

// Footer
$footer-bg: #111;
$footer-bottom-bg: #131313;
$footer-color: rgba(255, 255, 255, 0.8);
$footer-link-color: rgba(255, 255, 255, 0.7);
$footer-link-color-hover: rgba(255, 255, 255, 0.5);
