blockquote {
	border-left: 2px solid #eaeaea;
    padding: 10px 0 10px 40px;
    margin: 40px 0;
    transition: all .3s ease-in-out;
	font-weight: 500;
	color: #222;
	p {
		font-size: 1.25rem;
	}
}

// email notification icon
.mail_outline {
	.notification {
		position: absolute;
		width: 8px;
		height: 8px;
		background: #2ac32a;
		border-radius: 4px;
		display: block;
		top: -2px;
		right: -2px;
	}
}

.fullscreen {
	padding: 0;
	height: 100vh;
}

.main-navigation {
	padding: 30px;
}

.toolbar > a {
	padding: 20px;
}

.shopping-cart {
	.item {
		.item-remove {
			border: none;
		}
	}
}

.form-control {
	margin-bottom: 10px;
}

label.radio {
	input[type="radio"] {
		opacity: 1;
		z-index: inherit;
	}
}

.dotted {
	.container {
		&:before {
			content: "";
			background: url('./gridtile.png') repeat fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: absolute;
		}
	}
}

.owl-carousel {
	.easyzoom-flyout {
		img {
			width: auto;
		}
	}
}

.play-video > i {
	font-size: inherit;
}

.img-inverse {
	-webkit-filter: invert(1);
	filter: invert(1);
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff!important;
    background: #333!important;
}

.block-post img {
	max-width: 100%;
}

@media(max-width: 991px) {
	#navbar_main {
		background: rgba(255,255,255,0.6);
		a {
			color: #333 !important;
		}
		a.active {
			background: none !important;
		}
	}
}

.text-black {
	color: #000!important;
}
.navbar-inverse {
	.text-black {
		color: #fff!important;
	}
}
html {
  scroll-behavior: smooth;
}

// Ribbons
.product {
	.ribbon-wrap {
		width: 100%;
		height: 188px;
		position: absolute;
		top: -8px;
		left: 8px;
		overflow: hidden;
	}
	.ribbon-wrap:before, .wrap:after {
		content: "";
		position: absolute;
	}
	.ribbon-wrap:before {
		width: 40px;
		height: 8px;
		right: 100px;
		background: #4D6530;
		border-radius: 8px 8px 0px 0px;
	}
	.ribbon-wrap:after {
		width: 8px;
		height: 40px;
		right: 0px;
		top: 100px;
		background: #4D6530;
		border-radius: 0px 8px 8px 0px;
	}
	.ribbon6 {
		color: white;
		width: 200px;
		height: 40px;
		line-height: 40px;
		position: absolute;
		top: 30px;
		right: -50px;
		z-index: 2;
		overflow: hidden;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		border: 1px dashed;
		box-shadow:0 0 0 3px #17a2b8,  0px 21px 5px -18px rgba(0,0,0,0.4);
		background: #17a2b8;
		text-align: center;
		font-size: 0.8em;
	}
}

.navbar-opaque.bg-dark {
	background-color: #075264 !important;
	img {
		filter: brightness(0) invert(1);
	}
}

.heading {
	color: #075264;
}
